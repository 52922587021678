export function noUiSliderInit() {
	console.log('🟢 noUiSliderInit')
	if (document.getElementById("slider")) {
		noUiSlider.create(document.getElementById("slider"), {
			start: [3.5, 200],
			connect: true,
			range: {
				min: 3.5,
				max: 200,
			},
		});

		const slider = document.getElementById("slider");
		const filterFrom = document.getElementById("filter-from");
		const filterTo = document.getElementById("filter-to");

		slider.noUiSlider.on('update', function(values, handle) {
			const value = parseFloat(values[handle]).toFixed(1);
			if (handle === 0) {
				filterFrom.querySelector('.number').textContent = value;
			} else {
				filterTo.querySelector('.number').textContent = value;
			}
		});

		document.getElementById("filter-from").addEventListener("change", function (e) {
			minValue = e.target.value;
			document.getElementById("slider").noUiSlider.set([e.target.value, null])
		})
		document.getElementById("filter-to").addEventListener("change", function (e) {
			maxValue = e.target.value;
			document.getElementById("slider").noUiSlider.set([null, e.target.value])
		})
	}
}
