// import AOS from "aos";
import lozad from "lozad";
import { header } from "./header";
import swiperInit from "./swiper";
import {
	setBackgroundElement,
	ToggleItem,
	detectCloseElement,
	buttonToTop,
	toggleCta,
	clickScrollToDiv,
	appendCaptchaASP,
	toggleBookPin,
} from "./helper";
import { noUiSliderInit } from "./nouislider";
import { tocInit } from "./tocbot";
import { careerModal } from "./career";
import { toggleFilterMobile } from "./tour";
$(document).ready(function () {
	header.init();
	swiperInit();
	buttonToTop();
	toggleCta();
	setBackgroundElement();
	ToggleItem();
	noUiSliderInit();
	tocInit();
	toggleBookPin();
	careerModal();
	toggleFilterMobile();
	countDownTime();
	expandText();
	datePicker();
});
/*==================== Date Picker ====================*/
function datePicker() {
	$("#date, .datepicker").datepicker({
		minDate: 0, // This sets the minimum date to today
		dateFormat: "yy-mm-dd",
		beforeShowDay: function (date) {
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			return [date >= today, ""];
		},
	});
}
/*==================== Expand Text ====================*/
function expandText() {
	$("p:has(a.text-blue-600)").each(function () {
		const $p = $(this);
		const $expandLink = $p.find("a.text-blue-600").eq(0);
		const $collapseLink = $p.find("a.text-blue-600").eq(1);
		const fullText = $p.clone().children().remove().end().text().trim();
		const words = fullText.split(/\s+/);

		if (words.length > 70) {
			const shortText = words.slice(0, 70).join(" ") + "... ";

			function toggleText(e) {
				e.preventDefault();
				const isExpanded = $p.data("expanded");
				if (isExpanded) {
					$p.html(shortText);
					$p.append($expandLink).append($collapseLink);
					$expandLink.show();
					$collapseLink.hide();
				} else {
					$p.html(fullText);
					$p.append($expandLink).append($collapseLink);
					$expandLink.hide();
					$collapseLink.show();
				}
				$p.data("expanded", !isExpanded);
			}

			$p.html(shortText);
			$p.append($expandLink).append($collapseLink);
			$collapseLink.hide();

			$expandLink.on("click", toggleText);
			$collapseLink.on("click", toggleText);

			$p.data("expanded", false);
		} else {
			$expandLink.hide();
			$collapseLink.hide();
		}
	});
}
/*==================== Count Down Time ====================*/
function countDownTime() {
	$(".count-down").each(function () {
		const $this = $(this);
		const $span = $this.find("span").first();
		const timeString = $span.text();
		const [hours, minutes, seconds] = timeString.split(":").map(Number);

		let totalSeconds = hours * 3600 + minutes * 60 + seconds;

		const timer = setInterval(() => {
			totalSeconds--;

			if (totalSeconds <= 0) {
				clearInterval(timer);
				$this.text("Expired");
			} else {
				const h = Math.floor(totalSeconds / 3600);
				const m = Math.floor((totalSeconds % 3600) / 60);
				const s = totalSeconds % 60;

				$span.text(
					`${h.toString().padStart(2, "0")}:${m
						.toString()
						.padStart(2, "0")}:${s.toString().padStart(2, "0")}`
				);
			}
		}, 1000);
	});
}

/*==================== Aos Init ====================*/
// AOS.init({
// 	offset: 100,
// });
/*==================== Lazyload JS ====================*/
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
const backgroundObserver = lozad(".lozad-bg", {
	threshold: 0.1,
});
observer.observe();
backgroundObserver.observe();

